import * as React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Switch as RouterSwitch, Route, } from "react-router-dom";

import { ErrorBoundary } from "components/errorBoundary";
import PageSpinner from "components/pageSpinner";
import { handleAnonymousError } from "errorPages/anonymousError";
import { AjaxManager, useAjaxManager } from "utils/ajax";
import { withHeader, Meta } from "utils/header";
import withI18n, { t } from "utils/i18n";
import { emitNotificationOnQueryParam } from "utils/notification";
import { OrgCookie, useOrgCookie } from "utils/orgCookie";
import { initializeSentry } from "utils/sentry";

import ForgotPassword from "./forgotPasswordForm";
import { LoginTypeSwitch } from "./loginTypeSwitch";
import RegisterForm from "./registerForm";
import ResetPassword from "./resetPasswordForm";
import theme from "./theme.module.scss";

import "main.css";
// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const _main = require("main.module.scss");

const ENTRYPOINT_KEY_ANON = "anon";

class App extends React.Component<AppProps> {

    componentDidMount() {
        this.handleNotifications();
    }

    handleNotifications() {
        emitNotificationOnQueryParam("CollectCompleted", "True", t("CollectControlResult_Completed_Title"), "success");
        emitNotificationOnQueryParam("CollectAlreadyComplete", "True", t("CollectControlResult_AlreadyCompleted_Title"), "warning");
        emitNotificationOnQueryParam("CollectArchived", "True", t("CollectControlResult_Archived_Title"), "warning");
        emitNotificationOnQueryParam("ReviewRejected", "True", t("ReviewResult_Rejected_Title") + " - " + t("ReviewResult_Rejected_Description"), "success");
        emitNotificationOnQueryParam("ReviewApproved", "True", t("ReviewResult_Approved_Title"), "success");
        emitNotificationOnQueryParam("ReviewAlreadyComplete", "True", t("ReviewResult_AlreadyCompleted_Title"), "warning");
        emitNotificationOnQueryParam("ReviewControlNotCompletedYet", "True", t("ReviewResult_ControlNotCompletedYet_Title"), "warning");
        emitNotificationOnQueryParam("ReviewArchived", "True", t("ReviewResult_Archived_Title") + " - " + t("ReviewResult_Archived_Description"), "warning");
    }

    render() {
        const { ajaxManager, orgCookie } = this.props;

        return (<div className={theme.app}><RouterSwitch>
            <Route exact path="/">
                <Meta title={() => t("Login_Title")}/>
                <LoginTypeSwitch ajaxManager={ajaxManager} orgCookie={orgCookie} orgProfileCookie={null} />
            </Route>
            <Route exact path="/register/:token" render={props =>
                <>
                    <Meta title={() => t("RegisterUser_Title")} />
                    <RegisterForm ajaxManager={ajaxManager} orgCookie={orgCookie} {...props} />
                </>} />
            <Route exact path="/register" render={props =>
                <>
                    <Meta title={() => t("RegisterUser_Title")} />
                    <RegisterForm ajaxManager={ajaxManager} orgCookie={orgCookie} {...props} />
                </>} />
            <Route path="/reset-password/:token" render={props =>
                <>
                    <Meta title={() => t("ResetPassword_Title")}/>
                    <ResetPassword ajaxManager={ajaxManager} orgCookie={orgCookie} {...props} />
                </>} />
            <Route exact path="/reset-password" render={props =>
                <>
                    <Meta title={() => t("ResetPassword_Title")}/>
                    <ResetPassword ajaxManager={ajaxManager} orgCookie={orgCookie} {...props} />
                </>} />
            <Route exact path="/forgot-password" render={() =>
                <>
                    <Meta title={() => t("LoginFlow_ForgotYourPassword")} />
                    <ForgotPassword ajaxManager={ajaxManager} orgCookie={orgCookie} />
                </>} />
            <Route render={() => <>
                <Meta title={() => t("ErrorPage_NotFound")} />
                {handleAnonymousError(404)}
            </>} />
        </RouterSwitch></div>);
    }
}

interface AppProps {
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
}

const AppWithI18n = withI18n<AppProps>(withHeader<AppProps>(App));

const LocalizedApp = () => {
    const orgCookie = useOrgCookie();
    const ajaxManager = useAjaxManager();

    return (
        <ErrorBoundary routeKey={ENTRYPOINT_KEY_ANON}>
            <Router>
                <React.Suspense fallback={<PageSpinner />}>
                    <AppWithI18n
                        ajaxManager={ajaxManager}
                        orgCookie={orgCookie!}
                    />
                </React.Suspense>
            </Router>
        </ErrorBoundary>
    );
};

initializeSentry(ENTRYPOINT_KEY_ANON, "v2");
const container = document.getElementById("app");
const root = createRoot(container!);
root.render(<LocalizedApp />);
