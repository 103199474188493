import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { UserOutlined } from "@ant-design/icons";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CardFooter, FormTitle, FormSubtitle, Button } from "@imperocom/ui";
import { Input } from "antd";
import axios from "axios";


import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { AjaxManager } from "utils/ajax";
import { isEmailValid } from "utils/form";
import { t } from "utils/i18n";
import { emitNotification } from "utils/notification";
import { OrgCookie } from "utils/orgCookie";


import theme from "./theme.module.scss";


const FormItem = Form.Item;

class ForgotPasswordInternal extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
    private captchaRef: React.RefObject<HCaptcha>;

    constructor(props: ForgotPasswordProps) {
        super(props);

        this.captchaRef = React.createRef();

        this.state = {
            status: { type: "DEFAULT" },
        };
    }

    render() {
        const { orgCookie } = this.props;
        const { getFieldDecorator } = this.props.form;
        const params = new URLSearchParams(window.location.search);
        const email = params.get("email");

        return (
            <FormCardContainer rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <FormTitle title={t("LoginFlow_ForgotYourPassword")} />
                <FormSubtitle subtitle={t("LoginFlow_ResetPasswordEmail")} />
                <FormCard onSubmitForm={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator("email", {
                            rules: [{
                                required: true,
                                message: t("LoginFlow_InputCorrectEmail"),
                            }, {
                                validator: (_rule: any, value: string | undefined, callback: any) => {
                                    if (!isEmailValid(value)) {
                                        callback(t("LoginFlow_InputCorrectEmail"));
                                    } else {
                                        callback();
                                    }
                                },
                            }],
                            initialValue: email,
                        })(
                            <Input prefix={<UserOutlined className={theme.iconOpacity} />} placeholder={t("Login_UserName")} />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator("captcha", {
                            rules: [{ required: true }],
                        })(
                            <HCaptcha
                                size="normal"
                                sitekey={IMPERO_HCAPTCHA_SITE_KEY}
                                onVerify={this.handleCaptchaVerify}
                                ref={this.captchaRef}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        <Button
                            id="loginFormSubmitBtn"
                            type="primary"
                            htmlType="submit"
                            block>
                            {t("LoginFlow_SendMeAnEmail")}
                        </Button>
                    </FormItem>
                </FormCard>

                <CardFooter>
                    <a href="https://impero.com/get-in-touch/" target="_blank">{t("LoginFlow_DontHaveAccount")}</a>
                </CardFooter>
            </FormCardContainer>);
    }

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: ForgotPasswordProps) => {
            if (!err) {
                const { ajaxManager } = this.props;
                ajaxManager.ajax<{}, ForgotPasswordStatus>(
                    () => axios.post(`${APIRoute.ROOT}/request-reset-password`, { "email": values.email ?? "", "humanVerificationResponse": values.captcha ?? "" }), {
                        component: this,
                        initialStatusValue: "DEFAULT",
                        getErrorStatus: () => ({ type: "DEFAULT" }),
                        getSuccessStatus: () => ({ type: "DEFAULT" }),
                        inFlightStatus: { type: "REQUESTING_RESET" },
                    })
                    .then(() => {
                        emitNotification("success", t("RequestResetPassword_EmailHasBeenSent"));
                    });
            }
        });
    };

    private handleCaptchaVerify = (captcha: string | null) => {
        this.props.form.setFieldsValue({
            captcha,
        });
    };
}

interface ForgotPasswordProps extends FormComponentProps {
    email?: string,
    captcha?: string,
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
}

type ForgotPasswordStatus = { type: "DEFAULT" } | { type: "REQUESTING_RESET" } | { type: "INVALID_TOKEN" };
interface ForgotPasswordState {
    status: ForgotPasswordStatus,
}

const ForgotPassword = Form.create<ForgotPasswordProps>()(ForgotPasswordInternal);

export default ForgotPassword;
