import * as React from "react";

import { Button, CardFooter, FormSubtitle, FormTitle } from "@imperocom/ui";
import { Typography } from "antd";
import FormItem from "antd/es/form/FormItem";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import passwordSvg from "img/password-login.svg";
import { t } from "utils/i18n";
import { OrgCookie } from "utils/orgCookie";
import { loginRedirectToRoot } from "utils/url";

interface DisplayRecoveryCodeProps {
    recoveryCode: string,
    orgCookie: OrgCookie,
}

class DisplayRecoveryCode extends React.PureComponent<DisplayRecoveryCodeProps> {

    render = () => {
        const { orgCookie, recoveryCode } = this.props;
        return (
            <FormCardContainer rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <FormTitle title={t("Setup2FA_RecoveryCode")} />
                <FormSubtitle subtitle={t("Setup2FA_RecoveryDescription")} />
                <FormCard>
                    <FormItem>
                        <DisplayRecoveryCodeTitle recoveryCode={recoveryCode} />
                    </FormItem>
                    <FormItem>
                        <Button
                            id="copy-button"
                            onClick={this.handleLogin}
                            type="primary"
                            block>
                            {t("Login_LoginButton")}
                        </Button>
                    </FormItem>
                </FormCard>
                <CardFooter />
            </FormCardContainer>
        );
    };

    handleLogin = () => {
        loginRedirectToRoot();
    };
}

interface DisplayRecoveryCodeTitleProps {
    recoveryCode: string,
}

function DisplayRecoveryCodeTitle(props: DisplayRecoveryCodeTitleProps) {
    const { Title } = Typography;
    return <Title code={true} level={4} copyable>{props.recoveryCode}</Title>;
}

export { DisplayRecoveryCode, DisplayRecoveryCodeTitle };
