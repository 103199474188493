import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { LockOutlined } from "@ant-design/icons";
import { CardFooter, FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import { InputRef, Input } from "antd";
import axios, { AxiosError } from "axios";


import { SwitchToTwoFactor } from "anon/loginTypeSwitch";
import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { LoginTimeout } from "types/user/user/auth";
import { AjaxManager } from "utils/ajax";
import { IMPERO_X_RECOVERY_CODE } from "utils/auth";
import { t } from "utils/i18n";
import { OrgCookie } from "utils/orgCookie";

import theme from "./theme.module.scss";


const FormItem = Form.Item;

class RecoveryFormInternal extends React.PureComponent<RecoveryFormProps, RecoveryFormState> {
    private pinInput: React.RefObject<InputRef>;

    constructor(props: RecoveryFormProps) {
        super(props);
        this.pinInput = React.createRef();

        this.state = {
            status: { type: "DEFAULT" },
        };
    }

    componentDidMount() {
        if (this.pinInput.current) { this.pinInput.current.focus(); }
    }

    handleSubmit = (e: React.FormEvent) => {
        const { challenge, onSwitchToTwoFactor } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err: any, values: RecoveryFormProps) => {
            if (!err) {
                const { pin } = values;
                if (this.state.status.type !== "DEFAULT") {
                    return;
                }
                this.setState({ status: { type: "LOGGING_IN" } });
                // TODO: migrate to AJAX manager when it supports headers in the happy path
                axios.post<{}>(`${APIRoute.ROOT}/validate-pin`, { pin, challenge })
                    .then(res => {
                        const recoveryCode = res.headers[IMPERO_X_RECOVERY_CODE];
                        if (recoveryCode !== undefined) {
                            onSwitchToTwoFactor({ type: "DISPLAY_RECOVERY", recoveryCode });
                        }
                        this.setState({ status: { type: "DEFAULT" } });
                    })
                    .catch((err: AxiosError<LoginTimeout>) => {
                        if (err.response?.status === 408) {
                            const data: LoginTimeout = err.response.data;
                            if (data.cause === "credentials") {
                                this.props.onNeedCredentials();
                            }
                        }
                        this.setState({ status: { type: "DEFAULT" } });
                    });
            }
        });
    };

    render() {
        const { orgCookie } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <FormCardContainer rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <FormTitle title={t("Setup2FA_RecoveryCode")} />
                <FormSubtitle subtitle={t("Login2FA_RecoveryCodeDescription")} />
                <FormCard onSubmitForm={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator("pin", {
                            rules: [{ required: true, message: t("Login_PinCodeOrRecoveryCode") }],
                        })(
                            <Input
                                prefix={<LockOutlined className={theme.iconOpacity} />}
                                placeholder={t("Setup2FA_RecoveryCode")}
                                ref={this.pinInput} />
                        )}
                    </FormItem>
                    <Button
                        id="recovery-code"
                        type="primary"
                        htmlType="submit"
                        block>
                        {t("Login_LoginButton")}
                    </Button>
                </FormCard>

                <CardFooter>
                    <Button
                        id="recovery-link"
                        type="link"
                        onClick={this.handleSwitchToTwoFactor}>
                        {t("Login2FA_LoginWithPin")}
                    </Button>
                </CardFooter>
            </FormCardContainer>);
    }

    handleSwitchToTwoFactor = () => {
        const { challenge, onSwitchToTwoFactor } = this.props;
        onSwitchToTwoFactor({ type: "DEFAULT", challenge });
    };
}


interface RecoveryFormProps extends FormComponentProps {
    pin?: string,
    onSwitchToTwoFactor: (params: SwitchToTwoFactor) => void,
    onNeedCredentials: () => void,
    challenge: string,
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
}

type RecoveryFormStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" };
interface RecoveryFormState {
    status: RecoveryFormStatus,
}

const RecoveryForm = Form.create<RecoveryFormProps>()(RecoveryFormInternal);

export default RecoveryForm;
