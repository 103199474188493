import * as React from "react";

import { FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import axios, { AxiosError } from "axios";


import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import { PinCodeForm } from "components/twoFactor";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { LoginTimeout } from "types/user/user/auth";
import { AjaxManager } from "utils/ajax";
import { t } from "utils/i18n";
import { OrgCookie } from "utils/orgCookie";
import { loginRedirectToRoot } from "utils/url";

/*
 * Two factor form
 */
export default class TwoFactorForm extends React.Component<TwoFactorFormProps, TwoFactorFormState> {
    constructor(props: TwoFactorFormProps) {
        super(props);
        this.state = {
            status: { type: "DEFAULT" },
            wrongPin: false,
        };
    }

    render() {
        const { wrongPin } = this.state;
        const { orgCookie } = this.props;
        return (
            <FormCardContainer rightSide={<
                RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <FormTitle title={t("MyProfile_TwoFactor_Title")} />
                <FormSubtitle id={"subtitle-sms-sent"} subtitle={t("Setup2FA_Subtitle")} />

                <PinCodeForm challenge={this.props.challenge} onSubmitPin={this.handleSubmitPin} onSwitchToRecovery={this.handleSwitchToRecovery} submitButtonText={t("Login_LoginButton")} wrongPin={wrongPin}>
                    <Button
                        id="recovery-link"
                        type="link"
                        onClick={this.handleSwitchToRecovery}>
                        {t("Login2FA_ForgotYourDevice")}
                    </Button>
                </PinCodeForm>
            </FormCardContainer>
        );
    }

    handleSwitchToRecovery = () => {
        const { challenge, onSwitchToRecovery } = this.props;
        onSwitchToRecovery(challenge);
    };

    handleSubmitPin = (pin: string) => {
        this.setState({ wrongPin: false });
        const { ajaxManager } = this.props;
        ajaxManager.ajax<{}, TwoFactorFormStatus>(
            () => axios.post(`${APIRoute.ROOT}/validate-pin`, { pin, challenge: this.props.challenge }), {
                component: this,
                initialStatusValue: "DEFAULT",
                getSuccessStatus: () => ({ type: "DEFAULT" }),
                getErrorStatus: () => ({ type: "DEFAULT" }),
                inFlightStatus: { type: "VALIDATING_PIN" },
                onErrorCode: {
                    401: () => this.setState({ wrongPin: true }),
                    403: () => this.props.onNeedCredentials(),
                    408: (err: AxiosError) => {
                        if (err.response) {
                            const data: LoginTimeout = err.response.data as LoginTimeout;
                            switch (data.cause) {
                                case "pinCode":
                                    this.setState({ wrongPin: true });
                                    break;
                                case "credentials":
                                    this.props.onNeedCredentials();
                                    break;
                            }
                        }
                    },
                },
            })
            .then(loginRedirectToRoot);
    };
}
interface TwoFactorFormProps {
    onSwitchToRecovery: (challenge: string) => void,
    onNeedCredentials: () => void,
    challenge: string,
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
}

type TwoFactorFormStatus = { type: "DEFAULT" } | { type: "VALIDATING_PIN" };
interface TwoFactorFormState {
    status: TwoFactorFormStatus,
    wrongPin: boolean,
}
