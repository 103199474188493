import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { CardFooter, FormTitle, FormSubtitle, Button } from "@imperocom/ui";
import { Input, Popover, Spin } from "antd";
import axios from "axios";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";


import { LOGIN_STATE_PARAM, LOGIN_STATE_SETUP_2FA, LOGIN_STATE_TWO_FACTOR, CHALLENGE } from "anon/loginTypeSwitch";
import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import linkBrokenSvg from "img/link-broken.svg";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { AjaxManager } from "utils/ajax";
import { IMPERO_WWW_AUTHENTICATION_LOGIN, IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS, IMPERO_WWW_AUTHENTICATION_SMS } from "utils/auth";
import { buildUserDictionary, confirmNormalPassword, Callback } from "utils/confirmPassword";
import { t } from "utils/i18n";
import { emitNotification } from "utils/notification";
import { OrgCookie } from "utils/orgCookie";


import theme from "./theme.module.scss";


const FormItem = Form.Item;

class RegisterFormInternal extends React.Component<RegisterFormProps, RegisterFormState> {
    private token: string | null;

    constructor(props: RegisterFormProps) {
        super(props);
        this.state = {
            confirmDirty: false,
            status: { type: "DEFAULT" },
        };
        const searchParams = new URLSearchParams(window.location.search);
        this.token = searchParams.get("token");
    }

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser = () => {
        const { ajaxManager } = this.props;
        ajaxManager.ajax<User, RegisterFormStatus>(
            () => axios.get(`${APIRoute.ROOT}/register?token=` + encodeURIComponent(this.token ?? "")), {
                component: this,
                initialStatusValue: "DEFAULT",
                getSuccessStatus: () => ({ type: "DEFAULT" }),
                getErrorStatus: () => ({ type: "DEFAULT" }),
                inFlightStatus: { type: "LOGGING_IN" },
                onErrorCode: {
                    422: () => this.setState({ status: { type: "INVALID_TOKEN" } }),
                    404: () => window.location.replace("/"),
                },
            })
            .then((user: User) => this.setState({ user }));
    };

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: RegisterFormProps) => {
            if (!err) {
                const { password } = values;
                const { ajaxManager } = this.props;
                ajaxManager.ajax<{}, RegisterFormStatus>(
                    () => axios.post(`${APIRoute.ROOT}/register?token=` + encodeURIComponent(this.token ?? ""), {
                        password,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        human_verification_response: "",
                    }), {
                        component: this,
                        initialStatusValue: "DEFAULT",
                        getSuccessStatus: () => ({ type: "DEFAULT" }),
                        getErrorStatus: () => ({ type: "DEFAULT" }),
                        inFlightStatus: { type: "LOGGING_IN" },
                        onErrorCode: {
                            400: () => {
                                emitNotification("error", t("ChangePassword_NewPassword_Invalid"));
                            },
                            401: e => {
                                const authType = e.response && e.response.headers["www-authenticate"] || undefined;
                                const challenge = (e.response && (e.response.data as any).challenge as (string | null)) || "";
                                switch (authType) {
                                    case IMPERO_WWW_AUTHENTICATION_LOGIN:
                                        // The registration should already have passed 1FA
                                        throw new Error("Registration failed!");
                                    case IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS:
                                        this.props.history.push(`/?${LOGIN_STATE_PARAM}=${encodeURIComponent(LOGIN_STATE_SETUP_2FA)}&${CHALLENGE}=${encodeURIComponent(challenge)}`);
                                        break;
                                    case IMPERO_WWW_AUTHENTICATION_SMS:
                                        this.props.history.push(`/?${LOGIN_STATE_PARAM}=${encodeURIComponent(LOGIN_STATE_TWO_FACTOR)}&${CHALLENGE}=${encodeURIComponent(challenge)}`);
                                        break;
                                    default:
                                        throw new Error(t("Login_UnknownAuthType") + ": " + authType);
                                }
                            },
                            404: () => {
                                emitNotification("error", t("RegisterUser_LinkIsIncorrect"));
                            },
                        },
                    })
                    .then(() => window.location.assign("/"));
            }
        });
    };

    handleConfirmPassword = (rule: any, value: string, callback: Callback) => {
        const userDictionary = buildUserDictionary(this.props.orgCookie, this.state.user);
        confirmNormalPassword(rule, value, callback, userDictionary);
        this.validateToNextPassword(rule, value, callback);
    };

    compareToFirstPassword = (rule: any, value: any, callback: Function) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue("password")) {
            callback(t("PasswordRepeatIncorrect"));
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule: any, value: string, callback: Function) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    handleConfirmBlur = (e: any) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    hasErrors(fieldsError: Object) {
        const { form } = this.props;
        const password = form.getFieldValue("password");
        const confirm = form.getFieldValue("confirm");

        if (!password || password === "" || !confirm || confirm === "") {
            return true;
        } else {
            return Object.values(fieldsError).some(err => err);
        }
    }

    render() {
        const { orgCookie } = this.props;
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { user, status } = this.state;
        const email = user ? user.email : t("RegisterUser_YourAccount");

        const PasswordStrengthPopover = (
            <div className={theme.passwordPopover}>
                <p>{t("PasswordRule1")}</p>
                <p>{t("PasswordRule2")}</p>
                <p>{t("PasswordRule3")}</p>
                <p>{t("PasswordRule4")}</p>
            </div>
        );

        if (status.type === "INVALID_TOKEN") {
            return (
                <FormCardContainer rightSide={<RightSideIcon icon={linkBrokenSvg} customImages={true} orgCookie={orgCookie} />}>
                    <FormTitle title={t("RegisterUser_LinkIsIncorrect")} />
                    <FormSubtitle subtitle={t("RegisterUser_LinkIsIncorrectSubtitle")} />
                </FormCardContainer>
            );
        }

        return (
            <FormCardContainer rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <Spin spinning={!user}>
                    <FormTitle title={t("RegisterUser_Title")} />
                    <FormSubtitle subtitle={t("RegisterUser_Subtitle", { email })} />
                    <FormCard onSubmitForm={this.handleSubmit}>
                        <FormItem>
                            {getFieldDecorator("password", {
                                rules: [{ validator: this.handleConfirmPassword }],
                            })(
                                <Input type="password" placeholder={t("RegisterUser_Password")} />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator("confirm", {
                                rules: [{ validator: this.compareToFirstPassword }],
                            })(
                                <Input type="password" placeholder={t("RegisterUser_PasswordRepeat")} onBlur={this.handleConfirmBlur} />
                            )}
                        </FormItem>
                        <Button
                            id="registerFormSubmitBtn"
                            type="primary"
                            htmlType="submit"
                            disabled={this.hasErrors(getFieldsError())}
                            loading={this.state.status.type === "LOGGING_IN"} block>
                            {t("RegisterUser_SubmitPassword_Button")}
                        </Button>
                    </FormCard>
                    <CardFooter>
                        <Popover content={PasswordStrengthPopover} placement="bottom" trigger="click">
                            <Link to="/">{t("PasswordRules")}</Link>
                        </Popover>
                    </CardFooter>
                </Spin>
            </FormCardContainer>
        );
    }
}

interface RegisterFormProps extends FormComponentProps, RouteComponentProps {
    password?: string,
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
}

interface User {
    email: string,
    lastName: string,
    firstName: string,
}

type RegisterFormStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" } | { type: "INVALID_TOKEN" };
interface RegisterFormState {
    status: RegisterFormStatus,
    confirmDirty?: boolean,
    user?: User,
}

const RegisterForm = Form.create<RegisterFormProps>()(withRouter(RegisterFormInternal));

export default RegisterForm;
