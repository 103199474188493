import * as React from "react";

import { CardFooter, FormTitle, FormSubtitle } from "@imperocom/ui";
import axios from "axios";


import FormCardContainer from "components/formCardContainer";
import { EmailForm, EmailFormStatus } from "components/login/emailForm";
import { PasswordForm } from "components/login/passwordForm";
import RightSideIcon from "components/rightSideIcon";
import { Setup2FA } from "components/twoFactor";
import { DisplayRecoveryCode } from "components/twoFactor/displayRecovery";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { LoginOption } from "types/web/org";
import { AjaxManager } from "utils/ajax";
import { addHashToFlashCookie } from "utils/flash";
import { t } from "utils/i18n";
import { emitNotification } from "utils/notification";
import { assertNever } from "utils/obj";
import { OrgCookie } from "utils/orgCookie";
import { OrgProfileCookie } from "utils/profileCookie";
import { getDomainName } from "utils/url";

import RecoveryForm from "../recoveryForm";
import TwoFactorForm from "../twoFactorForm";


import theme from "./theme.module.scss";


const LOGIN_STATE_PARAM = "login-state";
const LOGIN_STATE_SETUP_2FA = "setup-2fa";
const LOGIN_STATE_TWO_FACTOR = "two-factor";
const CHALLENGE = "challenge";

class NotAuthenticable extends React.PureComponent<NotAuthenticableProps> {
    render() {
        const { orgCookie } = this.props;

        return (
            <FormCardContainer rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}>
                <FormTitle title={t("Login_SignIntoOrg", { org: orgCookie.name })} />
                <FormSubtitle subtitle={t("Login_Disabled")} />
                <p className={theme.notAuthenticableText}>{t("Login_DisabledText")}</p>
                <CardFooter />
            </FormCardContainer>
        );
    }
}

interface NotAuthenticableProps {
    orgCookie: OrgCookie,
}

type SwitchToTwoFactor = { type: "DEFAULT", challenge: string } | { type: "DISPLAY_RECOVERY", recoveryCode: string };

class LoginTypeSwitch extends React.Component<SwitchProps, SwitchState> {
    constructor(props: SwitchProps) {
        super(props);
        const url = new URL(window.location.href);
        const { searchParams } = url;
        const loginState = searchParams.get(LOGIN_STATE_PARAM) ?? undefined;
        const challenge = searchParams.get(CHALLENGE) ?? undefined;

        let status: SwitchStatus;
        if (loginState) {
            switch (loginState.toLowerCase()) {
                case LOGIN_STATE_SETUP_2FA:
                    status = { type: "SETUP_2FA", challenge: challenge! };
                    break;
                case LOGIN_STATE_TWO_FACTOR:
                    status = { type: "TWO_FACTOR", challenge: challenge! };
                    break;
                default:
                    status = { type: "LOGIN" };
                    break;
            }
            searchParams.delete(LOGIN_STATE_PARAM);
            searchParams.delete(CHALLENGE);
            if (window.history && window.history.pushState) {
                window.history.pushState({ path: url.toString() }, "", url.toString());
            }
        } else {
            status = { type: "LOGIN" };
        }
        this.state = {
            status,
        };
    }

    render = () => {
        const { status } = this.state;
        const { ajaxManager, orgCookie, orgProfileCookie } = this.props;
        switch (status.type) {
            case "LOGIN": {
                return <EmailForm
                    rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}
                    title={t("Login_SignIntoOrg", { org: orgCookie.name })}
                    footer={
                        <CardFooter>
                            <a href={`https://login.${getDomainName()}?reset=true`}>{t("Login_ChangeOrganization")}</a>
                        </CardFooter>
                    }
                    onSubmitEmail={(email, emailForm) => {
                        ajaxManager.ajax<LoginOption, EmailFormStatus>(
                            () => axios.post<LoginOption>(APIRoute.LOGIN_OPTIONS, {
                                email,
                            }), {
                                // This is not very elegant, but it works.
                                component: emailForm,
                                initialStatusValue: "DEFAULT",
                                getErrorStatus: () => ({ type: "DEFAULT" }),
                                inFlightStatus: { type: "LOGGING_IN" },
                                getSuccessStatus: () => {
                                    return { type: "DEFAULT" };
                                },
                            })
                            .then((options: LoginOption): void => {
                                let newStatus: SwitchStatus;
                                switch (options.type) {
                                    case "PasswordAuthentication":
                                        newStatus = {
                                            type: "PASSWORD",
                                            email,
                                            requiresHumanVerification: options.requiresHumanVerification,
                                        };
                                        break;
                                    case "SSOAuthentication":
                                        this.handleSSO(email);
                                        return;
                                    case "PasswordOrSSOAuthentication":
                                        newStatus = {
                                            type: "PASSWORD_OR_SSO",
                                            email,
                                            requiresHumanVerification: options.requiresHumanVerification,
                                        };
                                        break;
                                    case "NotAuthenticable":
                                        newStatus = { type: "NOT_AUTHENTICABLE" };
                                        break;
                                    default:
                                        return assertNever(options);
                                }
                                this.setState({
                                    status: newStatus,
                                });
                            });
                    }} />;
            }
            case "PASSWORD": {
                return (
                    <PasswordForm
                        rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}
                        title={t("Login_SignIntoOrg", { org: orgCookie.name })}
                        hasSSO={false}
                        hasForgotPassword={true}
                        email={status.email}
                        apiRouteLogin={`${APIRoute.ROOT}/login`}
                        requiresHumanVerification={status.requiresHumanVerification}
                        onSwitchToSetupTwoFactor={this.switchToSetupTwoFactor}
                        onSwitchToTwoFactor={this.switchToTwoFactor}
                        onSwitchToSSO={() => this.handleSSO(status.email)}
                        onSwitchToEmailForm={this.switchToEmailForm}
                        ajaxManager={ajaxManager}
                    />
                );
            }
            case "PASSWORD_OR_SSO": {
                return (
                    <PasswordForm
                        rightSide={<RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}
                        title={t("Login_SignIntoOrg", { org: orgCookie.name })}
                        hasSSO={true}
                        hasForgotPassword={true}
                        email={status.email}
                        apiRouteLogin={`${APIRoute.ROOT}/login`}
                        requiresHumanVerification={status.requiresHumanVerification}
                        onSwitchToSetupTwoFactor={this.switchToSetupTwoFactor}
                        onSwitchToTwoFactor={this.switchToTwoFactor}
                        onSwitchToSSO={() => this.handleSSO(status.email)}
                        onSwitchToEmailForm={this.switchToEmailForm}
                        ajaxManager={ajaxManager}
                    />
                );
            }
            case "SETUP_2FA": {
                return <Setup2FA loggedIn={false} challenge={status.challenge} ajaxManager={ajaxManager} orgCookie={orgCookie} orgProfileCookie={orgProfileCookie} />;
            }
            case "TWO_FACTOR": {
                return <TwoFactorForm onSwitchToRecovery={this.switchToRecovery} onNeedCredentials={this.onNeedCredentials} challenge={status.challenge} ajaxManager={ajaxManager} orgCookie={orgCookie} />;
            }
            case "RECOVERY": {
                return <RecoveryForm onSwitchToTwoFactor={this.switchToTwoFactor} onNeedCredentials={this.onNeedCredentials} challenge={status.challenge} ajaxManager={ajaxManager} orgCookie={orgCookie} />;
            }
            case "NOT_AUTHENTICABLE": {
                return <NotAuthenticable orgCookie={orgCookie} />;
            }
            case "DISPLAY_RECOVERY": {
                return <DisplayRecoveryCode recoveryCode={status.recoveryCode} orgCookie={orgCookie} />;
            }
            default:
                return assertNever(status);
        }
    };

    switchToSetupTwoFactor = (challenge: string) => {
        this.setState({ status: { type: "SETUP_2FA", challenge } });
    };

    switchToTwoFactor = (params: SwitchToTwoFactor) => {
        switch (params.type) {
            case "DEFAULT":
                this.setState({ status: { type: "TWO_FACTOR", challenge: params.challenge } });
                break;
            case "DISPLAY_RECOVERY":
                this.setState({ status: { type: "DISPLAY_RECOVERY", recoveryCode: params.recoveryCode } });
                break;
            default:
                assertNever(params);
        }
    };

    switchToRecovery = (challenge: string) => {
        this.setState({ status: { type: "RECOVERY", challenge } });
    };

    switchToEmailForm = () => {
        this.setState({ status: { type: "LOGIN" } });
    };

    onNeedCredentials = () => {
        this.setState({ status: { type: "LOGIN" } });
        emitNotification("error", t("LoginFlow_LoginExpired"));
    };

    handleSSO = (email: string) => {
        if (window.location.hash && window.location.hash.length > 1) {
            addHashToFlashCookie(window.location.hash);
        }
        window.location.assign(APIRoute.SSO + "?username=" + encodeURIComponent(email));
    };
}

interface SwitchProps {
    ajaxManager: AjaxManager,
    orgCookie: OrgCookie,
    orgProfileCookie: OrgProfileCookie | null,
}

interface SwitchState {
    status: SwitchStatus,
}

type SwitchStatus =
    { type: "LOGIN" }
    | { type: "PASSWORD", email: string, requiresHumanVerification: boolean }
    | { type: "PASSWORD_OR_SSO", email: string, requiresHumanVerification: boolean }
    | { type: "SETUP_2FA", challenge: string }
    | { type: "TWO_FACTOR", challenge: string }
    | { type: "RECOVERY", challenge: string }
    | { type: "DISPLAY_RECOVERY", recoveryCode: string }
    | { type: "NOT_AUTHENTICABLE" };

export {
    LOGIN_STATE_PARAM,
    LOGIN_STATE_SETUP_2FA,
    LOGIN_STATE_TWO_FACTOR,
    CHALLENGE,
    LoginTypeSwitch,
    type SwitchToTwoFactor,
};
