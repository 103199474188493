
const IMPERO_WWW_AUTHENTICATION_LOGIN = "Login";
const IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS = "SetupAndSms";
const IMPERO_WWW_AUTHENTICATION_SMS = "Sms";
const IMPERO_X_RECOVERY_CODE = "x-impero-recoverycode";

export {
    IMPERO_WWW_AUTHENTICATION_LOGIN,
    IMPERO_WWW_AUTHENTICATION_SETUP_AND_SMS,
    IMPERO_WWW_AUTHENTICATION_SMS,
    IMPERO_X_RECOVERY_CODE,
};
